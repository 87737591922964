<template>
  <div>
    <my-nav-bar
      title="商品明细"
      left-text="返回"
      left-arrow
    />
    <div :class="isFinished ? ' app-main-container' : 'app-main-fixed-container'">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <div v-if="list.length > 0 && !isFinished" class="tips">
        提示：左滑删除
      </div>
      <van-swipe-cell
        v-for="retrieve in list"
        :key="retrieve.id"
      >
        <van-cell title="处理人" :value="retrieve.employee_name" />
        <van-cell title="处理时间" :value="retrieve.created_at" />
        <van-cell title="涉及金额" :value="'￥' + retrieve.amount" value-class="amount" />
        <van-cell v-if="retrieve.good_volume > 0" title="取回完好的" :value="`${retrieve.good_volume + retrieve.after_goods.least_unit}`" />
        <van-cell v-if="retrieve.bad_volume > 0" title="取回损坏的" :value="`${retrieve.bad_volume + retrieve.after_goods.least_unit}`" />
        <settle-handle-goods
          class="goods"
          :goods="retrieve.after_goods"
          :show-remark="false"
        />
        <template v-if="!isFinished" #right>
          <van-button type="danger" block square style="height: 100%" @click="handleDelete(retrieve)">删除</van-button>
        </template>
      </van-swipe-cell>

    </div>
    <div v-if="!isFinished" class="fixed-bottom-btn">
      <van-button block square @click="toRetrieveView">取回商品</van-button>
    </div>
  </div>
</template>

<script>
import MyNavBar from '@/components/my-nav-bar'
import SettleHandleGoods from './components/goods'
import { getRetrieves, deleteRetrievedGoods } from '@/api/settle_handle'
export default {
  components: { MyNavBar, SettleHandleGoods },
  data() {
    return {
      list: [],
      showEmpty: false,
      isFinished: this.$route.query.status !== '0'
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      const params = {
        after_order_diff_id: this.$route.query.after_order_diff_id
      }
      getRetrieves(params).then(res => {
        this.list = res.data
        this.showEmpty = this.list.length === 0
      })
    },
    toRetrieveView() {
      this.$router.push({
        path: 'settle-handle-retrieve',
        query: {
          after_order_diff_id: this.$route.query.after_order_diff_id
        }
      })
    },
    handleDelete(retrieve) {
      this.confirm('确定删除吗？').then(() => {
        const data = {
          after_order_diff_id: this.$route.query.after_order_diff_id,
          after_order_retrieve_id: retrieve.id
        }
        deleteRetrievedGoods(data).then(res => {
          this.success(res.msg)
          this.getList()
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .app-main-fixed-container, .app-main-container {
    padding: 15px 15px 5px 15px;
  }
  .goods {
    background-color: #fff;
    margin-bottom: 10px;
    padding: 10px;
  }
  .tips {
    color: #aaa;
    padding-bottom: 10px;
  }
  @import '@/styles/color.scss';
  .amount {
    color: $amountRedColor;
  }
</style>
