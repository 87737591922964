var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "商品明细",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    class: _vm.isFinished ? ' app-main-container' : 'app-main-fixed-container'
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _vm.list.length > 0 && !_vm.isFinished ? _c('div', {
    staticClass: "tips"
  }, [_vm._v(" 提示：左滑删除 ")]) : _vm._e(), _vm._l(_vm.list, function (retrieve) {
    return _c('van-swipe-cell', {
      key: retrieve.id,
      scopedSlots: _vm._u([!_vm.isFinished ? {
        key: "right",
        fn: function fn() {
          return [_c('van-button', {
            staticStyle: {
              "height": "100%"
            },
            attrs: {
              "type": "danger",
              "block": "",
              "square": ""
            },
            on: {
              "click": function click($event) {
                return _vm.handleDelete(retrieve);
              }
            }
          }, [_vm._v("删除")])];
        },
        proxy: true
      } : null], null, true)
    }, [_c('van-cell', {
      attrs: {
        "title": "处理人",
        "value": retrieve.employee_name
      }
    }), _c('van-cell', {
      attrs: {
        "title": "处理时间",
        "value": retrieve.created_at
      }
    }), _c('van-cell', {
      attrs: {
        "title": "涉及金额",
        "value": '￥' + retrieve.amount,
        "value-class": "amount"
      }
    }), retrieve.good_volume > 0 ? _c('van-cell', {
      attrs: {
        "title": "取回完好的",
        "value": "".concat(retrieve.good_volume + retrieve.after_goods.least_unit)
      }
    }) : _vm._e(), retrieve.bad_volume > 0 ? _c('van-cell', {
      attrs: {
        "title": "取回损坏的",
        "value": "".concat(retrieve.bad_volume + retrieve.after_goods.least_unit)
      }
    }) : _vm._e(), _c('settle-handle-goods', {
      staticClass: "goods",
      attrs: {
        "goods": retrieve.after_goods,
        "show-remark": false
      }
    })], 1);
  })], 2), !_vm.isFinished ? _c('div', {
    staticClass: "fixed-bottom-btn"
  }, [_c('van-button', {
    attrs: {
      "block": "",
      "square": ""
    },
    on: {
      "click": _vm.toRetrieveView
    }
  }, [_vm._v("取回商品")])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }